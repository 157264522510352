import React, { useEffect } from "react";
import { graphql } from "gatsby";

// Hooks Querys & Context
import { QueryFragments } from "@querys/queryFragments"; // eslint-disable-line

import ModuleZone from "~moduleZone";
import Layout from "@components/layout";
import { PageMeta } from "@components/pageMeta";
import { AnimatePresence, motion } from "framer-motion";
import { useSiteContext } from "@lib/context";

const IndexPage = ({ data: { page } }) => {
  const { pageModules, seo, pageColour } = page || {};
  const [animate, setAnimate] = React.useState(true);
  const { state, setState } = useSiteContext();

  //setAnimate(false) after 6 seconds
  useEffect(() => {
    setTimeout(() => {
      setAnimate(false);
    }, 4500);
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setState(false);
    }, 5000);
  }, []);

  return (
    <Layout pageColour={pageColour} animate={animate}>
      <PageMeta {...seo} />
      {/* <div className="h-screen w-screen absolute flex items-center justify-center pointer-events-none ">
        <Animation openingLottie={openingLottie} />
      </div> */}

      {pageModules && <ModuleZone {...pageModules} anim={true} />}
      <AnimatePresence exitBeforeEnter>
        {animate && state && (
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed top-0 left-0 w-full h-screen bg-white z-[999]"
          />
        )}
      </AnimatePresence>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      pageColour {
        title
        value
      }
      pageModules: modules {
        ...PageModules
      }
      openingLottie {
        asset {
          url
        }
      }
    }
  }
`;
